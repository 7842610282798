import React from 'react';
import { PageWithSubNav, Paragraph } from 'docComponents';
import styles from '../../index.module.scss';

const IndexPage = () => {
  return (
    <PageWithSubNav
      pageType="design"
      title="February 2020"
      subnav="release-notes">
      <div id="February2020" className={styles.release}>
        <Paragraph className="uni-margin--one--vert">
          The last month has come and gone, with a big surprise coming next
          month. Start counting down the days until Uniform Version 4—we will
          be!
        </Paragraph>
      </div>
    </PageWithSubNav>
  );
};

export default IndexPage;
